<template>
  <div id="neutralElectrodeTestRecord">
    <el-dialog
      :title="neutralElectrodeTestRecordFormTitle"
      width="1200px"
      :visible.sync="neutralElectrodeTestRecordDialogVisible"
      :close-on-click-modal="false"
      @close="neutralElectrodeTestRecordDialogClose"
    >
      <el-form
        ref="neutralElectrodeTestRecordFormRef"
        :model="neutralElectrodeTestRecordForm"
        :rules="neutralElectrodeTestRecordFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="neutralElectrodeTestRecordForm.productName"
                readonly
                :disabled="neutralElectrodeTestRecordFormTitle !== '新增一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '修改一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="neutralElectrodeTestRecordForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="neutralElectrodeTestRecordFormTitle !== '新增一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '修改一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="neutralElectrodeTestRecordForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="neutralElectrodeTestRecordFormTitle !== '新增一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '修改一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="计划生产数量" prop="plannedQuantity">
              <el-input
                v-model="neutralElectrodeTestRecordForm.plannedQuantity"
                placeholder="请输入计划生产数量"
                clearable
                :disabled="neutralElectrodeTestRecordFormTitle !== '新增一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '修改一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验日期" prop="inspectionDate">
              <el-date-picker
                v-model="neutralElectrodeTestRecordForm.inspectionDate"
                placeholder="请选择检验日期"
                value-format="yyyy-MM-dd"
                :disabled="neutralElectrodeTestRecordFormTitle !== '新增一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '修改一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验类别" prop="identifyCategory">
              <el-input
                v-model="neutralElectrodeTestRecordForm.identifyCategory"
                readonly
                :disabled="neutralElectrodeTestRecordFormTitle !== '新增一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '修改一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验依据" prop="testBased">
              <el-input
                v-model="neutralElectrodeTestRecordForm.testBased"
                readonly
                :disabled="neutralElectrodeTestRecordFormTitle !== '新增一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '修改一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="recordDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="process"
              title="工序"
              width="150"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="standard"
              title="标准要求"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="inspectionQuantity"
              title="抽检数量"
              width="100"
              :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
            />
            <vxe-table-colgroup title="抽检检验结果" header-align="center">
              <vxe-table-column
                field="qualifiedQuantity"
                title="合格品数"
                width="100"
                :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
              />
              <vxe-table-column
                field="unqualifiedQuantity"
                title="不合格品数"
                width="120"
                :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
              />
            </vxe-table-colgroup>
            <vxe-table-column
              field="measure"
              title="不合格品处理措施初步评审"
              width="120"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: [
                  { value: '返工', label: '返工' },
                  { value: '返修', label: '返修' },
                  { value: '报废', label: '报废' },
                  { value: '其他', label: '其他' }
                ]
              }"
            />
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检验记录" prop="testRecord">
              <el-input
                v-model="neutralElectrodeTestRecordForm.testRecord"
                placeholder="请输入检验记录"
                type="textarea"
                :rows="3"
                :disabled="neutralElectrodeTestRecordFormTitle !== '新增一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '修改一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="neutralElectrodeTestRecordForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
                :disabled="neutralElectrodeTestRecordFormTitle !== '新增一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '修改一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="neutralElectrodeTestRecordForm.reviewResult"
                :disabled="neutralElectrodeTestRecordFormTitle !== '复核一次性使用中性电极过程检验记录'
                  && neutralElectrodeTestRecordFormTitle !== '一次性使用中性电极过程检验记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="neutralElectrodeTestRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="neutralElectrodeTestRecordFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['NEUTRAL_PROCESS_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="neutralElectrodeTestRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="plannedQuantity" label="计划生产数量" />
      <el-table-column label="检验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="identifyCategory" label="检验类别" />
      <el-table-column prop="testBased" label="检验依据" />
      <el-table-column prop="testRecord" label="检验记录" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate1">{{ scope.row.inspectionDate1.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['NEUTRAL_PROCESS_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['NEUTRAL_PROCESS_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['NEUTRAL_PROCESS_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="neutralElectrodeTestRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addNeutralElectrodeTestRecord,
  deleteNeutralElectrodeTestRecord,
  updateNeutralElectrodeTestRecord,
  selectNeutralElectrodeTestRecordInfo,
  selectNeutralElectrodeTestRecordList,
  reviewNeutralElectrodeTestRecord
} from '@/api/quality/neutralElectrodeTestRecord'

export default {
  data () {
    return {
      neutralElectrodeTestRecordDialogVisible: false,
      neutralElectrodeTestRecordFormTitle: '',
      neutralElectrodeTestRecordForm: {
        id: '',
        productName: '一次性使用中性电极',
        spec: '',
        batchNo: '',
        plannedQuantity: '',
        inspectionDate: '',
        identifyCategory: '过程检验',
        testBased: '一次性使用中性电极过程检验规程',
        testRecord: '',
        remarks: '',
        reviewResult: '',
        status: '',
        recordDetailJson: '',
        taskId: ''
      },
      neutralElectrodeTestRecordFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      neutralElectrodeTestRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      },
      recordDetailList: [
        { process: '组装', standard: '电线与电极板连接应牢固', measure: '' },
        { process: '整件检验', standard: '1、目测电线有无破损，表面是否光滑，有无杂质\n2、导通性：用万用表R*1Ω档测量负极板铆钉连接处与插头线的导通电阻应小于10Ω', measure: '' },
        { process: '黏贴海绵', standard: '黏贴是否平整', measure: '' },
        { process: '封小包装（特殊）', standard: '封口应牢固，封口处至少应能承受30N拉力', measure: '' }
      ]
    }
  },
  created () {
    selectNeutralElectrodeTestRecordList(this.searchForm).then(res => {
      this.neutralElectrodeTestRecordPage = res
    })
  },
  methods: {
    neutralElectrodeTestRecordDialogClose () {
      this.$refs.neutralElectrodeTestRecordFormRef.resetFields()
      this.recordDetailList = [
        { process: '组装', standard: '电线与电极板连接应牢固', measure: '' },
        { process: '整件检验', standard: '1、目测电线有无破损，表面是否光滑，有无杂质\n2、导通性：用万用表R*1Ω档测量负极板铆钉连接处与插头线的导通电阻应小于10Ω', measure: '' },
        { process: '黏贴海绵', standard: '黏贴是否平整', measure: '' },
        { process: '封小包装（特殊）', standard: '封口应牢固，封口处至少应能承受30N拉力', measure: '' }
      ]
    },
    neutralElectrodeTestRecordFormSubmit () {
      if (this.neutralElectrodeTestRecordFormTitle === '一次性使用中性电极过程检验记录详情') {
        this.neutralElectrodeTestRecordDialogVisible = false
        return
      }
      this.$refs.neutralElectrodeTestRecordFormRef.validate(async valid => {
        if (valid) {
          if (this.neutralElectrodeTestRecordFormTitle === '新增一次性使用中性电极过程检验记录') {
            this.neutralElectrodeTestRecordForm.id = ''
            this.neutralElectrodeTestRecordForm.status = 1
            this.neutralElectrodeTestRecordForm.recordDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addNeutralElectrodeTestRecord(this.neutralElectrodeTestRecordForm)
          } else if (this.neutralElectrodeTestRecordFormTitle === '修改一次性使用中性电极过程检验记录') {
            this.neutralElectrodeTestRecordForm.recordDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateNeutralElectrodeTestRecord(this.neutralElectrodeTestRecordForm)
          } else if (this.neutralElectrodeTestRecordFormTitle === '复核一次性使用中性电极过程检验记录') {
            this.neutralElectrodeTestRecordForm.status = 2
            await reviewNeutralElectrodeTestRecord(this.neutralElectrodeTestRecordForm)
          }
          this.neutralElectrodeTestRecordPage = await selectNeutralElectrodeTestRecordList(this.searchForm)
          this.neutralElectrodeTestRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.neutralElectrodeTestRecordFormTitle = '新增一次性使用中性电极过程检验记录'
      this.neutralElectrodeTestRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteNeutralElectrodeTestRecord(row.id)
        if (this.neutralElectrodeTestRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.neutralElectrodeTestRecordPage = await selectNeutralElectrodeTestRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.neutralElectrodeTestRecordFormTitle = '修改一次性使用中性电极过程检验记录'
      this.neutralElectrodeTestRecordDialogVisible = true
      this.selectNeutralElectrodeTestRecordInfoById(row.id)
    },
    handleReview (index, row) {
      this.neutralElectrodeTestRecordFormTitle = '复核一次性使用中性电极过程检验记录'
      this.neutralElectrodeTestRecordDialogVisible = true
      this.selectNeutralElectrodeTestRecordInfoById(row.id)
    },
    handleInfo (index, row) {
      this.neutralElectrodeTestRecordFormTitle = '一次性使用中性电极过程检验记录详情'
      this.neutralElectrodeTestRecordDialogVisible = true
      this.selectNeutralElectrodeTestRecordInfoById(row.id)
    },
    selectNeutralElectrodeTestRecordInfoById (id) {
      selectNeutralElectrodeTestRecordInfo(id).then(res => {
        this.neutralElectrodeTestRecordForm.id = res.id
        this.neutralElectrodeTestRecordForm.productName = res.productName
        this.neutralElectrodeTestRecordForm.spec = res.spec
        this.neutralElectrodeTestRecordForm.batchNo = res.batchNo
        this.neutralElectrodeTestRecordForm.plannedQuantity = res.plannedQuantity
        this.neutralElectrodeTestRecordForm.inspectionDate = res.inspectionDate
        this.neutralElectrodeTestRecordForm.identifyCategory = res.identifyCategory
        this.neutralElectrodeTestRecordForm.testBased = res.testBased
        this.neutralElectrodeTestRecordForm.testRecord = res.testRecord
        this.neutralElectrodeTestRecordForm.remarks = res.remarks
        this.neutralElectrodeTestRecordForm.reviewResult = res.reviewResult
        this.neutralElectrodeTestRecordForm.status = res.status
        this.neutralElectrodeTestRecordForm.taskId = res.taskId
        this.recordDetailList = res.recordDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectNeutralElectrodeTestRecordList(this.searchForm).then(res => {
        this.neutralElectrodeTestRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectNeutralElectrodeTestRecordList(this.searchForm).then(res => {
        this.neutralElectrodeTestRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectNeutralElectrodeTestRecordList(this.searchForm).then(res => {
        this.neutralElectrodeTestRecordPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['NEUTRAL_PROCESS_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
