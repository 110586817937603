import axios from '@/common/axios'
import qs from 'qs'

export function addNeutralElectrodeTestRecord (data) {
  return axios({
    method: 'post',
    url: '/quality/neutralRecord/add',
    data: qs.stringify(data)
  })
}

export function deleteNeutralElectrodeTestRecord (id) {
  return axios({
    method: 'delete',
    url: '/quality/neutralRecord/delete/' + id
  })
}

export function updateNeutralElectrodeTestRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/neutralRecord/update',
    data: qs.stringify(data)
  })
}

export function selectNeutralElectrodeTestRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/neutralRecord/info/' + id
  })
}

export function selectNeutralElectrodeTestRecordList (query) {
  return axios({
    method: 'get',
    url: '/quality/neutralRecord/list',
    params: query
  })
}

export function reviewNeutralElectrodeTestRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/neutralRecord/updateReview',
    data: qs.stringify(data)
  })
}
